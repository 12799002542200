<template>
  <div class="home">
    <banner />
    <div class="centent">
      <div class="about_us">
        <div class="title">
          山东文保文物修复技术有限公司
        </div>
        <div class="titleEng">
          Shandong Wenbao Cultural Heritage Conservation Co. , Ltd
        </div>
        <el-row :gutter="20" class="content_introduce">
          <el-col :span="11">
            <div class="about-left">
              <img :src="contentUrl" alt="" class="content_img" />
            </div>
          </el-col>
          <el-col :span="13">
            <div class="introduces">
              <div class="introduce">
                山东文保文物修复技术有限公司位于济南市高新区，公司成立于2015年，注册资金1000万元。办公面积两千余平方米，目前职工100余人，为济南市重点文化扶持企业。
              </div>
              <div class="introduce">
                山东文保文物修复技术有限公司自成立以来为全国多个文物保护修复中心、各大博物馆，提
                供文物综合服务，服务内容包括：陶瓷器、金属器、以及书画古籍等文物的保护修复工作和展品
                复仿制工作，同时也承担文物预防性保护项目、文物保护修复实验室建立、设备、工器具、耗材
                供应等工作，与山东、河北、甘肃、江苏、广东、湖北、湖南、江西、内蒙古等各个博物馆都有
                长期合作且得到众多业界好评。
              </div>
              <div class="introduce">
                山东文保文物修复技术有限公司以专业为目标， 以技术为核心，坚持“同心，奋进，务实，
                感恩”的企业精神，在可移动文物保护领域中扎根成长。 公司文物保护实验室建立有全面完整的
                策略和体系，特聘有古籍书画保护修复.............
              </div>
              <el-button class="btn" @click="$router.push({ name: 'GeneralSituation' })">
                了解更多
              </el-button>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="content_introduce2">
          <el-col :span="10">
            <div class="about-left">
              <img :src="contentUrl" alt="" class="content_img" />
            </div>
          </el-col>
          <el-col :span="14">
            <div class="introduces">
              <div class="introduce">
                山东文保文物修复技术有限公司位于济南市高新区，公司成立于2015年，注册资金1000万元。办公面积两千余平方米，目前职工100余人，为济南市重点文化扶持企业。
              </div>
              <div class="introduce">
                山东文保文物修复技术有限公司自成立以来为全国多个文物保护修复中心、各大博物馆，提
                供文物综合服务.............
              </div>
              <el-button class="btn" @click="$router.push({ name: 'GeneralSituation' })">
                了解更多
              </el-button>
            </div>
          </el-col>
        </el-row>
        <div class="content_introduce3">
          <div class="about-left">
            <img :src="contentUrl" alt="" class="content_img" />
          </div>
          <div class="introduces">
            <div class="introduce">
              山东文保文物修复技术有限公司位于济南市高新区，公司成立于2015年，注册资金1000万元。办公面积两千余平方米，目前职工100余人，为济南市重点文化扶持企业。
            </div>
            <div class="introduce">
              山东文保文物修复技术有限公司自成立以来为全国多个文物保护修复中心、各大博物馆，提
              供文物综合服务.............
            </div>
            <el-button class="btn" @click="$router.push({ name: 'GeneralSituation' })">
              了解更多
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="efforts">
      <div class="Restoration">
        <div class="title">
          修复成果
        </div>
        <div class="titleEng">Restoration efforts</div>
        <el-row :gutter="27" class="list">
          <el-col :span="6" v-for="(it, index) in effortsData" :key="index">
            <div :style="{
              backgroundImage: 'url(' + it.imgUrl + ')', 'background-repeat': 'no-repeat',
              'background-position': 'center',
            }" class="img" :class="[hoverIndex === index ? 'bgimg-hover' : '']" @mouseenter="hoverIndex = index"
              @mouseleave="hoverIndex = -1" @click="toRepairProcessDetails(index)">
              <div class="case" :class="[hoverIndex === index ? 'img-hover' : '']">
                <div class="case_title" :class="[hoverIndex === index ? 'case-title-hover' : '']">
                  <span>{{ it.title }}</span>
                </div>
                <div class="case_bottom" v-show="hoverIndex === index">
                  <img src="../assets/img/home/achieve-icon.png" alt="" srcset="" class="achieve-icon">
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="27" class="list2">
          <el-col :span="12" v-for="(it, index) in effortsData" :key="index" style="margin-top: 20px;">
            <div :style="{
              backgroundImage: 'url(' + it.imgUrl + ')',
              'background-repeat': 'no-repeat',
              'background-position': 'center',
            }" class="img" :class="[hoverIndex === index ? 'bgimg-hover' : '']" @mouseenter="hoverIndex = index"
              @mouseleave="hoverIndex = -1" @click="toRepairProcessDetails(index)">
              <div class="case" :class="[hoverIndex === index ? 'img-hover' : '']">
                <div class="case_title" :class="[hoverIndex === index ? 'case-title-hover' : '']">
                  <span>{{ it.title }}</span>
                </div>
                <div class="case_bottom" v-show="hoverIndex === index">
                  <img src="../assets/img/home/achieve-icon.png" alt="" srcset="" class="achieve-icon">
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import content from '/src/assets/content.jpg'
import cann from '/src/assets/img/home/cann.png'
import metal from '/src/assets/img/home/achieve1.png'
import ceramics from '/src/assets/img/home/achieve2.png'
import paintingCalligraphy from '/src/assets/img/home/achieve3.png'
import ancientBooks from '/src/assets/img/home/achieve4.png'
import banner from '../components/banner.vue'
export default {
  name: 'Home',
  components: { banner },
  data() {
    return {
      contentUrl: content,
      cannUrl: cann,
      effortsData: [
        {
          title: '金属文物修复案例',
          imgUrl: metal,
        },
        {
          title: '陶瓷文物修复案例',
          imgUrl: ceramics,
        },
        {
          title: '书画文物修复案例',
          imgUrl: paintingCalligraphy,
        },
        {
          title: '古籍文物修复案例',
          imgUrl: ancientBooks,
        },
      ],
      // 鼠标移入的索引(修复成果)
      hoverIndex: -1
    }
  },
  methods: {
    toRepairProcessDetails(index) {
      this.$router.push({ name: 'RepairProcessDetails', params: { index: index } })
    },
  },
}
</script>

<style lang="scss" scoped>
.home {
  background-color: #F9F7F3;
}

@media screen and (min-width: 921px) {
  .about_us {
    width: 62.5%;
  }

  .home {
    padding-top: 120px;
  }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .about_us {
    width: 80%;
  }

  .home {
    padding-top: 120px;
  }
}

@media screen and (max-width: 768px) {
  .about_us {
    width: 90%;
  }

  .home {
    padding-top: 70px;
  }
}

.home {
  .centent {
    width: 100%;
    height: auto;
    background: url('~@/assets/img/home/about-bg.png') no-repeat;
    background-size: contain;
    background-position: center right;
    padding-bottom: 40px;

    .about_us {
      height: auto;
      margin: 0px auto;
      position: relative;

      .title {
        padding-top: 90px;
        font-size: 32px;
        font-family: PingFang SC, PingFang SC-Heavy;
        font-weight: 800;
        text-align: left;
        color: #333;
      }

      .titleEng {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: rgba(102, 102, 102, .4);
        line-height: 30px;
        padding-bottom: 18px;
        border-bottom: 1px solid #D8D8D8;
        position: relative;
      }

      .titleEng::before {
        content: "";
        display: block;
        width: 109px;
        height: 6px;
        background: #A40000;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      @media screen and (min-width: 921px) {
        .content_introduce {
          padding: 70px 0 80px 0;

          .about-left {
            padding: 0 0 40px 80px;
            background: url("../assets/img/home/about-left.png") bottom center no-repeat;
            background-size: contain;
            margin: 0;
            margin-right: 20px;
            margin-top: -40px;
            position: relative;

            .content_img {
              width: 100%;
              height: auto;
              max-width: 100%;
            }
          }

          .introduces {
            font-size: 16px;
            color: #666;
            line-height: 24px;
            position: relative;
            text-indent: 2em;

            .introduce {
              font-size: 14px;
              line-height: 24px;
            }

            .btn {
              font-size: 14px;
              width: 120px;
              height: 40px;
              background: #a40000;
              color: #fff;
              position: absolute;
              right: 0;
            }
          }
        }

        .content_introduce2 {
          display: none;
        }

        .content_introduce3 {
          display: none;
        }
      }

      @media screen and (min-width: 768px) and (max-width: 920px) {
        .content_introduce {
          display: none;
        }

        .content_introduce2 {
          .about-left {
            margin: 50px 0 40px;
            position: relative;

            .content_img {
              width: 100%;
              height: auto;
              max-width: 100%;
            }
          }

          .introduces {
            font-size: 16px;
            margin: 50px 0px 60px 0px;
            color: #666;
            line-height: 30px;
            position: relative;
            text-indent: 2em;

            .introduce {
              font-size: 14px;
              line-height: 30px;
            }

            .btn {
              background: #a40000;
              color: #fff;
              position: absolute;
              right: 0;
            }
          }
        }

        .content_introduce3 {
          display: none;
        }
      }

      @media screen and (max-width: 768px) {
        .content_introduce {
          display: none;
        }

        .content_introduce2 {
          display: none;
        }

        .content_introduce3 {
          .about-left {
            margin: 50px 0 40px;
            position: relative;

            .content_img {
              width: 100%;
              height: auto;
              max-width: 100%;
            }
          }

          .introduces {
            font-size: 16px;
            margin: 50px 0 60px 0px;
            line-height: 30px;
            position: relative;
            text-indent: 2em;

            .introduce {
              font-size: 14px;
              line-height: 30px;
            }

            .btn {
              width: 170px;
              height: 50px;
              background: #a40000;
              color: #fff;
              position: absolute;
              right: 0;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 921px) {
    .Restoration {
      width: 62.5%;
      padding-bottom: 64px;
    }

    .list2 {
      display: none;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 920px) {
    .Restoration {
      width: 80%;
      padding-bottom: 64px;
    }

    .list2 {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .Restoration {
      width: 90%;
      padding-bottom: 64px;
    }

    .list {
      display: none;
    }
  }

  .efforts {
    width: 100%;
    background: url('~@/assets/img/home/cann.png') center left no-repeat;
    background-size: contain;
    position: relative;

    .Restoration {
      margin: 0px auto;
      position: relative;

      .title {
        font-size: 32px;
        font-family: PingFang SC, PingFang SC-Heavy;
        font-weight: 800;
        text-align: left;
        color: #333;
      }

      .titleEng {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: left;
        color: rgba(102, 102, 102, .4);
        line-height: 30px;
        padding-bottom: 18px;
        border-bottom: 1px solid #D8D8D8;
        position: relative;
      }

      .titleEng::before {
        content: "";
        display: block;
        width: 109px;
        height: 6px;
        background: #A40000;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      .list {
        margin-top: 41px;
        margin-bottom: 30px;

        .img {
          width: 100%;
          height: 360px;
          font-size: 20px;
          background-size: cover;
          color: #fff;
          display: flex;
          flex-direction: column;
          transition: all .5s ease 2s;

          .case {
            padding: 20px;
            height: 100%;
            display: flex;
            flex-direction: column;

            .case_title {
              width: 100%;
              padding-bottom: 15px;
              border-bottom: 1px dashed rgba(255, 255, 255, .3);
            }

            .case-title-hover {
              display: flex;
              align-items: center;
              justify-content: center;
              border-bottom: none;
            }

            .case_bottom {
              display: flex;
              justify-content: center;
              margin-top: 15px;
            }
          }

          .img-hover {
            background: rgba(0, 0, 0, .4);
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .bgimg-hover {
          background-size: 180% 180%;
        }
      }

      .list2 {
        margin-top: 41px;
        margin-bottom: 30px;

        .img {
          width: 100%;
          height: 360px;
          font-size: 20px;
          background-size: cover;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          transition: all .5s ease 2s;

          .case {
            padding: 20px;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            .case_title {
              width: 100%;
              text-align: center;
              padding-bottom: 15px;
              border-bottom: 1px dashed rgba(255, 255, 255, .3);
            }

            .case-title-hover {
              display: flex;
              align-items: center;
              justify-content: center;
              border-bottom: none;
            }

            .case_bottom {
              display: flex;
              justify-content: center;
              margin-top: 15px;
            }
          }

          .img-hover {
            background: rgba(0, 0, 0, .4);
          }
        }
      }
    }

    img {
      width: 42.3%;
      height: 1000px;
      position: absolute;
      top: 0;
      left: 0;
    }

    .achieve-icon {
      position: static;
      width: 44px;
      height: 44px;
    }
  }
}

.el-popover {
  .community_popover {
    display: flex;
    align-items: center;

    .community_left {
      color: #000;
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .community_title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      .community_qq {
        font-size: 16px;
      }
    }

    .community_qq_qR_code {
      width: 110px;
      height: 110px;
      padding: 35px 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .contact_popover {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 33px 35px;

    .contact_content {
      width: 260px;
      font-size: 16px;
      line-height: 35px;
    }

    .contact_popover_img {
      margin: 14px 0 13px;
    }
  }
}

.code-in /deep/ .el-input__inner {
  height: 50px;
  background: #ffffff;
  border: 1px solid #8f8f8f;
  border-radius: 36px;
}

@media screen and (max-width: 1680px) {
  .home .content {
    width: 80% !important;
  }

  .home .content .introduces {
    padding-top: 30px;
  }
}
</style>
